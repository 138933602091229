// App.tsx
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Now from './pages/Now';
import { motion, useScroll, useTransform } from "framer-motion";

import "./App.css";
import Portfolio from './pages/Portfolio';


function App() {
  const { scrollYProgress } = useScroll();
  const [scrollPercentage, setScrollPercentage] = useState(0);

  // Transform scrollYProgress to a percentage value (0 - 100)
  const percentage = useTransform(scrollYProgress, [0, 1], [0, 100]);

  // Update the local state whenever percentage changes
  useEffect(() => {
    return percentage.onChange(latest => {
      setScrollPercentage(latest);
    });
  }, [percentage]);

  return (
    <div className="bg-stone-950 flex flex-col">
     <div style={{ position: "fixed", top: 0, left: 0, width: "100%" }}>
      {/* Progress bar */}
      <motion.div
        style={{
          scaleX: scrollYProgress,
          transformOrigin: "0%",
          height: "5px",
          background: "#0000FF",
        }}
      />
    </div>
      <main className="m-0">
      <Router> 
        <Routes>
          <Route path={`${process.env.PUBLIC_URL}/`} element={<section id='index'><Home /></section>} />
          <Route path={`${process.env.PUBLIC_URL}/now`} element={<section id='now'><Now /></section>} />
          <Route path={`${process.env.PUBLIC_URL}/portfolio`} element={<section id='portofolio'><Portfolio /></section>} />
        </Routes>
      </Router>
      </main>
      <footer className='text-white pb-5'>
        <p className='font-raleway uppercase text-sm text-center text-white pt-20 font-bold'>R.Silva 2024</p>
      </footer>
    </div>
  );
}

export default App;
