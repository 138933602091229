import React, { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const baseUrl = 'https://www.rodrigosilva.live/mm17/images/2024';

const imageGroups = [
  {
    year: 2024,
    works: [
      { title: "Estoril Classics", folder: "estorilclassics" },
      { title: "Leiria Sobre Rodas", folder: "leiriasobrerodas" },
      { title: "Caramulo Motorfestival", folder: "caramulomotorfestival" }
    ]
  }
];

function Portfolio() {
  const [images, setImages] = useState({});
  const [modalImage, setModalImage] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading

  useEffect(() => {
    const fetchImages = async (folder) => {
      try {
        const response = await fetch(`https://www.rodrigosilva.live/mm17/images/2024/${folder}/`);
        if (!response.ok) throw new Error('Failed to fetch images');
        const data = await response.json();

        // Shuffle the images
        const shuffledImages = shuffleArray(data.images);
        setImages(prevImages => ({ ...prevImages, [data.folder]: shuffledImages }));
      } catch (error) {
        console.error(error);
      }
    };

    imageGroups[0].works.forEach(work => {
      fetchImages(work.folder);
    });
  }, []);

  // Function to shuffle the array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  };

  const handleImageClick = (folder, image) => {
    const highResUrl = `${baseUrl}/${folder}/${image}`;
    setLoading(true); // Set loading to true when opening modal
    setModalImage(highResUrl); // Set the modal image
  };

  const closeModal = () => {
    setModalImage(null); // Close the modal
    setLoading(false); // Reset loading when modal is closed
  };

  return (
    <>
      <section id="portfolio-section" className="*:selection:bg-primary *:selection:text-white flex flex-col justify-center px-5 min-h-screen text-white">
        <div id="portfolio-page" className="flex justify-center mt-20">
          <div className="grid grid-cols-1 w-5/6 md:w-4/5 lg:w-2/3 text-start">
            {imageGroups.map((group, groupIndex) => (
              <div key={groupIndex} className="mb-10">
                <h2 className="font-six-caps leading-none text-6xl font-bold uppercase mt-0 mb-6">
                  {group.year}
                </h2>
                {group.works.map((work, workIndex) => (
                  <div key={workIndex} className="mb-20">
                    <h3 className="font-raleway leading-none text-2xl font-bold uppercase mt-0 mb-4 text-end">
                      {work.title}
                    </h3>
                    <ResponsiveMasonry columnsCountBreakPoints={{ 250: 2, 600: 3 }}>
                      <Masonry gutter="30px">
                        {images[work.folder] && images[work.folder].length > 0 ? (
                          images[work.folder].map((image, i) => (
                            <img
                              key={i}
                              src={`${baseUrl}/${work.folder}_lowres/${image}`} // Use low-res images with the updated folder naming
                              className="hover:opacity-75 transition-opacity duration-300" // Add hover effect
                              style={{ width: "100%", display: "block", cursor: 'pointer' }}
                              alt={`${work.title} image ${i + 1}`}
                              onClick={() => handleImageClick(work.folder, image)} // Handle click
                            />
                          ))
                        ) : (
                          <p className="text-center">No images available for this work.</p>
                        )}
                      </Masonry>
                    </ResponsiveMasonry>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </section>

      {modalImage && ( // Modal
        <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50" onClick={closeModal}>
          {loading && ( // Loading indicator
            <span className="loading loading-infinity loading-lg"></span>
          )}
          <img
            src={modalImage}
            className={`max-w-full max-h-full ${loading ? 'hidden' : 'block'}`} // Hide the image until it loads
            alt="Modal"
            onLoad={() => setLoading(false)} // Set loading to false when the image loads
          />
        </div>
      )}

      <section id="now-insp" className="px-5 flex flex-col justify-center items-center text-center *:selection:bg-primary *:selection:text-white">
        <p className="font-raleway text-xs font-bold uppercase text-justify text-white">
          For more information &gt;&gt;
          <a
            href="mailto:motorminded17@gmail.com"
            rel="noreferrer"
            target="_blank"
            className="ml-1 lowercase bg-blue-600 hover:bg-blue-700"
          >
            motorminded17<span className="font-sarabun">@</span>gmail.com
          </a>
        </p>
      </section>
    </>
  );
}

export default Portfolio;