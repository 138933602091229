import arquivopt2024 from '../assets/img/Encontro_ciencia_2024_282.jpg'

function Awards() {
    return (
        <div>
             <div id="awards-page" className="flex justify-center mt-20">
                <div className='grid grid-cols-1 md:grid-cols-3 text-white sm:w-2/3 text-start'>

                    <div className="md:order-1 order-2 col-span-2 flex items-center">
                        <p className="text-sm font-raleway font-bold uppercase">
                            <a href="https://www.pt.pt/pt/noticias/premio-arquivo-pt-2024-pt-atribui-mencao-honrosa-a-projeto-de-final-de-curso-da-ubi/" className="">2024 <span className="bg-red">Arquivo.PT Award</span> honorable mention from <span className="bg-green font-semibold text-xs text-black">DNS.PT</span>
                            </a>
                            <p className='font-raleway text-xs font-bold uppercase text-justify'>
                            .PT awarded an Honorable Mention to the work “<i>ArquivoNC - o arquivo web do Jornal de Notícias da Covilhã</i>” (<i>ArquivoNC</i> - the web archive of the newspaper <i>Notícias da Covilhã</i>), a work within the scope of the end-of-course project in Computer Science and Engineering at the University of Beira Interior, which provides access to ten years of web pages of the newspaper Notícias da Covilhã from the news preserved by Arquivo.pt between 2009 and 2019.
                            </p>
                        </p>
                    </div>
                <div className="order-1 col-span-1 flex items-center justify-center md:ps-5 mb-3">
                    <img className="w-full md:max-w-80 md:max-h-80" src={arquivopt2024} alt="Image 1" />
                </div>
                </div>
            </div>
        </div>
    );
}
export default Awards