import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import About from "./About";
import Projects from "./Projects";
import Awards from "./Awards";

import { FaGithub } from "react-icons/fa";

function Home() {
  const [showMenu, setShowMenu] = useState(false);
  const [fadeClass, setFadeClass] = useState('opacity-0');


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setShowMenu(true);
        setTimeout(() => setFadeClass('opacity-100'), 300); // Trigger fade-in
      } else {
        setFadeClass('opacity-0');
        setTimeout(() => setShowMenu(false), 300); // Delay hiding to match fade-out duration
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className=' *:selection:bg-primary *:selection:text-white m-0 bg-stone-950'>
      {showMenu && (
        <div className={`fixed top-0 left-0 w-full bg-black/10 text-white p-5 flex justify-around z-50 transition-opacity duration-300 ${fadeClass} m-0`}>
          <a href="#index" className="font-raleway text-sm font-bold uppercase hover:bg-primary transition duration-500">
            Index
          </a>
          <a href="#about" className="font-raleway text-sm font-bold uppercase hover:bg-primary transition duration-500">
            About
          </a>
          <a href="#projects" className="font-raleway text-sm font-bold uppercase hover:bg-primary transition duration-500">
            Projects
          </a>
          <a href="#awards" className="font-raleway text-sm font-bold uppercase hover:bg-primary transition duration-500">
            Awards
          </a>
        </div>
      )}
      <section id="index" className="min-h-screen flex flex-col justify-center sm:items-end px-5">
        <div className='text-white sm:w-4/5 px-10 sm:px-0 text-start'>
          <p className="text-6xl md:text-9xl font-six-caps font-black text-shadow dark:text-secondary text-justify">
            RODRIGO SILVA
          </p>
        </div>
        <div className='text-white px-10 sm:p-0 sm:w-3/5 text-start'>
          <div className='text-white sm:w-2/3 text-start'>
            <p className="font-raleway text-sm font-bold uppercase text-justify">
              Enthusiastic about developing and building solutions. Student at <span>UBI</span>, Portugal. Currently, interested in the field of Data Science and ML. Looking forward to start my MSc in Computer Engineering, continuing my studies at UBI.
            </p>

            <p className="font-raleway text-sm font-bold uppercase text-justify my-1 inline-flex space-x-3">
              <a href="mailto:rd.silva@ubi.pt">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5 hover:text-primary hover:transition hover:duration-500 hover:ease-in-out">
                  <path d="M3 4a2 2 0 0 0-2 2v1.161l8.441 4.221a1.25 1.25 0 0 0 1.118 0L19 7.162V6a2 2 0 0 0-2-2H3Z" />
                  <path d="m19 8.839-7.77 3.885a2.75 2.75 0 0 1-2.46 0L1 8.839V14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V8.839Z" />
                </svg>
              </a>

              <a href="https://github.com/rdsilva01/archv" target="_blank" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="size-5 hover:text-yellow-300 hover:transition hover:duration-500 hover:ease-in-out">
                  <path fillRule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401Z" clipRule="evenodd" />
                </svg>
              </a>

              <a href="https://github.com/rdsilva01" target="_blank" rel="noopener noreferrer" className="hover:text-gray-400 hover:transition hover:duration-500 hover:ease-in-out">
                <FaGithub size={20} />
              </a>
            </p>
          </div>

          <div className='text-white sm:w-2/3 text-start mt-2 leading-tight'>
            <div className="m-0 p-0">
              <a href="#about" className="font-raleway leading-none text-sm font-bold uppercase hover:bg-primary focus:bg-primary transition duration-500 ease-in-out mt-0 mb-0">
                about
              </a>
            </div>
            <div className="m-0 p-0">
              <a href="#projects" className="font-raleway leading-none text-sm font-bold uppercase hover:bg-primary focus:bg-primary transition duration-500 ease-in-out mt-0 mb-0">
                projects
              </a>
            </div>
            <div className="m-0 p-0">
              <a href="#awards" className="font-raleway leading-none text-sm font-bold uppercase hover:bg-primary focus:bg-primary transition duration-500 ease-in-out mt-0 mb-0">
                awards
              </a>
            </div>
          </div>

        </div>
      </section>

      <section id="about" className="flex flex-col justify-center sm:items-end px-5 min-h-screen">
        <About />
      </section>
      <section id="projects" className="flex flex-col justify-top mt-20 pt-20 sm:items-end px-5 min-h-screen">
        <Projects />
      </section>
      <section id="awards" className="flex flex-col justify-top mt-20 pt-20 px-5 min-h-screen">
        <Awards />
      </section>
    </div>
  );
}

export default Home;
