import me from '../assets/img/me.png'
import { Link } from 'react-router-dom';

function About() {
    return (
        <div className="mt-20">
            <div id="about-page" className="flex justify-center items-center">
                <div className='grid grid-cols-1 md:grid-cols-5 text-white sm:w-2/3 text-start'>
                    <div className="md:order-1 order-2 col-span-4 flex items-center">
                        <p className="font-raleway text-sm font-bold uppercase text-justify">
                            Rodrigo Dias da Silva, student at the University of Beira Interior (<a className="bg-blue-600 hover:bg-blue-800/80 duration-300" href="https://www.ubi.pt/" target="_blank" rel='noreferrer'>UBI</a>), Portugal. Enthusiastic about developing minimalist systems and creating digital solutions. Currently, interested in the field of Data Science and looking forward to start my MSc in Computer Engineering, continuing my studies at UBI. Started my academic journey at UBI in 2021, pursuing a Bsc in Computer Science and Engineering. Developed a final Bsc project with the name of ArquivoNC (<a className="bg-red hover:bg-red/80 duration-300" href="https://arquivonc.ubi.pt" target="_blank" rel='noreferrer'>arquivonc.ubi.pt</a>), where it obtained a final grade of 19 out of 20. Additionally, the same project competed in the Arquivo.pt 2024 Award and received an Honorable Mention from the DNS.pt Association. Outside the status quo, I enjoy a variety of motorsports and   <Link to="/portfolio" className="text-black bg-yellow-400 hover:bg-yellow-400/80 duration-300">photography</Link>
                            {/* <a href="https://www.instagram.com/motorminded17" target='_blank' rel='noreferrer' className='text-black bg-yellow-400 hover:bg-yellow-400/80 duration-300' >amateur photography</a> */}
                            . Check out my <Link to="/now" className="bg-blue-600 hover:bg-blue-800/80 duration-300">/now</Link> page.
                        </p>
                    </div>
                    <div className="order-1 col-span-1 flex items-center justify-center mb-3">
                        {/* <img className="w-full max-h-32 max-w-32" src="https://2.gravatar.com/avatar/eb24c6fd4f47b86dce894d7b3f58855e01f1ca96ef0d63112023a6088d048c51?size=512"/> */}
                        <img className="w-full max-h-32 max-w-32 opacity-75" src={me}/>
                    </div>
                </div>
            </div>

                {/* timeline */}
                <div id="timeline" className="flex justify-center items-center mt-20">
                <div className="text-white sm:w-2/3">
                    <p className="text-md font-raleway font-bold uppercase mb-5">latest</p>
                        <ul className="timeline timeline-compact timeline-snap-icon timeline-vertical rounded-0">
                            <li>
                                <hr className="bg-white rounded-0" />
                                <div className="timeline-middle bg-white ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5">
                                    <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                    clipRule="evenodd" />
                                </svg>
                                </div>
                                <div className="timeline-start mb-10 text-xs font-raleway font-semibold uppercase pb-5">
                                <time className="font-mono italic">8 July 2024 - 6 September 2024</time>
                                <div className="text-sm font-raleway font-bold uppercase"><span className="bg-white text-black">Machine Learning Trainee</span> - Internship AT Cyient Celfinet PT</div>
                                    <a href="https://celfinet.com" className="bg-white text-black" target='_blank' rel='noreferrer'>Cyient Celfinet PT</a>  is a leading provider of telecom and network engineering services, specializing in delivering innovative solutions for network design, optimization, and performance management. The internship consisted in the <span className="font-bold">implementation of machine learning models to identify anomalies in seasonal time-series data</span> related to power consumption.
                                </div>
                                <hr className="bg-white rounded-0" />
                            </li>

                            <li>
                                <hr className="bg-white rounded-0" />
                                <div className="timeline-middle bg-white ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5">
                                    <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                    clipRule="evenodd" />
                                </svg>
                                </div>
                                <div className="timeline-start mb-10 text-xs font-raleway font-semibold uppercase pb-5">
                                <time className="font-mono italic">16 July 2024</time>
                                <div className="text-sm font-raleway font-bold uppercase"><a href="https://bip.inesctec.pt/noticias/inesc-tec-marca-presenca-no-encontro-ciencia-2024/" className="">INESC TEC marca presença no Encontro Ciência 2024 - <span className="bg-white text-black font-semibold text-xs">BIP</span></a><br />
                                <a href="https://www.ensino.eu/ensino-magazine/universidade/2024/encontro-ciencia-2024-ubi-ganha-duas-vezes/" className="">Encontro Ciência 2024: UBI ganha duas vezes - <span className="bg-yellow-400 text-black font-semibold text-xs">Ensino Magazine</span></a>
                                </div>
                                </div>
                                <hr className="bg-white rounded-0" />
                            </li>

                            <li>
                                <hr className="bg-white rounded-0" />
                                <div className="timeline-middle bg-white ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5">
                                    <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                    clipRule="evenodd" />
                                </svg>
                                </div>
                                <div className="timeline-start mb-10 text-xs font-raleway font-semibold uppercase pb-5">
                                <time className="font-mono italic">9 July 2024</time>
                                <div className="text-sm font-raleway font-bold uppercase"><a href="https://radio-covilha.pt/2024/07/noticias/docente-e-aluno-de-engenharia-informatica-da-ubi-distinguidos-no-encontro-ciencia-2024/" className=""> Docente e Aluno de Engenharia Informática da UBI distinguidos no Encontro Ciência 2024  - <span className="bg-yellow-400 text-xs text-black  font-semibold">Rádio Clube da Covilhã</span></a><br />
                                </div>
                                </div>
                                <hr className="bg-white rounded-0" />
                            </li>

                            <li>
                                <hr className="bg-white rounded-0" />
                                <div className="timeline-middle bg-white ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5">
                                    <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                    clipRule="evenodd" />
                                </svg>
                                </div>
                                <div className="timeline-start mb-10 text-xs font-raleway font-semibold uppercase pb-5">
                                <time className="font-mono italic">8 July 2024</time>
                                <div className="text-sm font-raleway font-bold uppercase"><a href="https://www.ubi.pt/Noticia/7809" className=""> Docente e Aluno de Engenharia Informática distinguidos no Encontro Ciência 2024  - <span className="bg-blue-600 text-xs font-semibold">UBI</span></a><br />
                                </div>
                                </div>
                                <hr className="bg-white rounded-0" />
                            </li>

                            <li>
                                <hr className="bg-white rounded-0" />
                                <div className="timeline-middle bg-white ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5">
                                    <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                    clipRule="evenodd" />
                                </svg>
                                </div>
                                <div className="timeline-start mb-10 text-xs font-raleway font-semibold uppercase pb-5">
                                <time className="font-mono italic">5 July 2024</time>
                                <div className="text-sm font-raleway font-bold uppercase"><span className="bg-red">ArquivoNC</span>: the web archive of <i>Jornal Notícias da Covilhã</i></div>
                                    The project ‘ArquivoNC: the web archive of Jornal Notícias da Covilhã’ is a project that vises the <span className="font-bold">preservation of the memory</span> of the city of Covilhã and the <span className="font-bold">legacy</span> of the newspaper Notícias da Covilhã. Developed in the scope of the final project of the Bsc. in Engenharia Informática at the Universidade da Beira Interior, under the guidance and supervision of Professor <a href="https://www.di.ubi.pt/~rcampos/index.html" className="bg-white text-black">Ricardo Campos</a>. The project was awarded an honorable mention from the DNS.pt association, at the 2024 edition of the Arquivo.pt Award.<br/>
                                    Visit it at <a className="bg-red hover:bg-red/80" href="https://arquivonc.ubi.pt" target="_blank">arquivonc.ubi.pt</a>
                                </div>
                                <hr className="bg-white rounded-0" />
                            </li>

                            <li>
                                <hr className="bg-white rounded-0" />
                                <div className="timeline-middle bg-white ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5">
                                    <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                    clipRule="evenodd" />
                                </svg>
                                </div>
                                <div className="timeline-start mb-10 text-xs font-raleway font-semibold uppercase pb-5">
                                <time className="font-mono italic">4 July 2024</time>
                                <div className="text-sm font-raleway font-bold uppercase"><a href="https://urbietorbi.ubi.pt/docente-e-aluno-de-informatica-da-ubi-recebem-mencao-honrosa/" className="">Docente e aluno de Informática da UBI recebem menção honrosa - <span className="bg-white text-black font-semibold text-xs">UrbiEtOrbi</span></a><br />
                                </div>
                                </div>
                                <hr className="bg-white rounded-0" />
                            </li>

                            <li>
                                <hr className="bg-white rounded-0" />
                                <div className="timeline-middle bg-white ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5">
                                    <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                    clipRule="evenodd" />
                                </svg>
                                </div>
                                <div className="timeline-start mb-10 text-xs font-raleway font-semibold uppercase pb-5">
                                <time className="font-mono italic">3 July 2024</time>
                                <div className="text-sm font-raleway font-bold uppercase"><a href="https://www.publico.pt/2024/07/03/ciencia/noticia/benfica-alteracoes-climaticas-descobrir-noticias-ja-jogo-2095904" className="">Da Covilhã ao site dos jornais - <span className="bg-red font-semibold text-xs">Público</span></a><br />
                                <a href="https://www.publico.pt/2024/07/03/ciencia/noticia/benfica-alteracoes-climaticas-descobrir-noticias-ja-jogo-2095904" className="">Dez anos de memória digital do NC recuperados em ‘site’ - <span className="bg-red font-semibold text-xs">Notícias da Covilhã</span></a>
                                </div>
                                </div>
                                <hr className="bg-white rounded-0" />
                            </li>
                            
                        </ul>
                    </div>
                </div>
                
            </div>
      
    );
}
export default About

//  Rodrigo Dias da Silva, student at the University of Beira Interior (<a className="bg-primary " href="https://www.ubi.pt/" target="_blank">UBI</a>), Portugal. I am passionate about developing minimalist systems and creating digital solutions. Currently, interested in the field of Data Science and looking forward to start my MSc. in Computer Engineering, continuing my studies at UBI. Started my academic journey at UBI in 2021, pursuing a Bachelor's degree in Computer Engineering. Developed a final Bsc. project with the name of ArquivoNC (<a className="bg-red hover:bg-red/80 " href="https://arquivonc.ubi.pt" target="_blank">arquivonc.ubi.pt</a>), where it obtained a final grade of 19 out of 20. Additionally, the same project competed in the Arquivo.pt 2024 Award and received an Honorable Mention from the DNS.pt Association. Outside the status quo, I enjoy all sorts of motorsports and do some amateur photography.